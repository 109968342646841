import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import StringArrayField from '@/shared/fields/string-array-field';
import BooleanField from '@/shared/fields/boolean-field';
import ImagesField from '@/shared/fields/images-field';
// import IntegerField from '@/shared/fields/integer-field';
// import EnumeratorField from '@/shared/fields/enumerator-field';
// import JsonField from '@/shared/fields/json-field';

function label(name) {
  return i18n(`entities.tourGuide.fields.${name}`);
}
// function enumeratorLabel(name, value) {
//   return i18n(`entities.plan.enumerators.${name}.${value}`);
// }

const fields = {
  id: new IdField('id', label('id')),
  fullName: new StringField('fullName', label('fullName'), {}),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {}),
  branchId: new StringField('branchId', label('branchId'), {}),
  email: new StringField('email', label('email'), {}),
  cityId: new StringField('cityId', label('cityId'), {}),
  companyId: new StringField('companyId', label('companyId'), {}),
  isMale: new BooleanField('disabled', label('disabled')),
  accountType: new StringField('accountType', label('accountType'), {}),
  languages: new StringArrayField('languages', label('languages'), {}),
  avatar: new ImagesField(
    'avatar',
    label('avatar'),
    '/saryahCompany/user/avatar', {
      max: 1
    },
  ),
  license:  new ImagesField(
    'avatar',
    label('avatar'),
    '/saryahCompany/user/avatar', {
      max: 1
    },
  ),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class TourGuideModel extends GenericModel {
  static get fields() {
    return fields;
  }
}