//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { TourGuideModel } from '@/modules/tour-guide/tour-guide-model';
import { FormSchema } from '@/shared/form/form-schema';
import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';
import i18n from '@/vueI18n';
import firebase from 'firebase/app';
import 'firebase/storage';
import { EventBus } from '@/event-bus.js';

// import { routerAsync } from '@/app-module';

const { fields } = TourGuideModel;
const formSchema = new FormSchema([
  fields.id,
  fields.fullName,
  fields.phoneNumber,
  fields.branchId,
  fields.cityId,
  fields.isMale,
  fields.languages,
  fields.avatar,
  fields.license,
  fields.companyId,
  fields.accountType,
  fields.email,
]);
export default {
  name: 'app-tour-guide-form-modal',
  
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    VuePhoneNumberInput,
  },

  created() {
    this.model = formSchema.initialValues(this.record);
    this.model.avatar = {
      publicUrl: undefined,
      name: undefined,
    };
    this.model.license = {
      publicUrl: undefined,
      name: undefined,
    };

    this.model.accountType = 'guideCompany';
    // console.log(this.model);
    // console.log(this.fields);
    // debugger

    // this.model.fullName = this.model.fullName || 'undefined'
    // this.model.phoneNumber = this.model.phoneNumber || 'undefined'

    // this.model.type = this.fields.type.options[1].value;
    // this.model.name = this.model.name || { en: 'undefined', ar: 'undefined' }
    // this.model.description = this.model.description || { en: 'undefined', ar: 'undefined' }
    // this.model.startCityId = "23701"
  },
  async mounted() {
    await this.doFetchCities();
    await this.doFetchBranches(this.currentUser.companyId);
  },
  data() {
    return {
      language: getLanguageCode(),
      rules: formSchema.rules(),
      model: null,
      citiesOptions: [],
      branchesOptions: [],
      popupShow: false,
      popupShowBranches: false,

      avatar: null,
      phoneNumber: null,
      languagesRows: [
        {
          id: 'ar',
          name: {
            en: 'Arabic',
            ar: 'عربى',
          },
        },
        {
          id: 'en',
          name: {
            en: 'English',
            ar: 'انجليزية',
          },
        },
      ],
      languagesOptions: [],
      selectedLanguages: [],
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
      CCode: '',
      isCCode: '',
      isMale: true,
      recordId: null,
      loadingImages: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    ...mapGetters({
      record: 'tourGuide/form/record',
      is_screen_xs: 'layout/is_screen_xs',
      citiesRows: 'city/list/rows',
      branchesRows: 'branch/list/rows',
      loadingCities: 'city/list/loading',
      currentUser: 'auth/currentUser',
      saveLoading: 'tourGuide/form/saveLoading',
    }),
    fields() {
      return fields;
    },
    cities() {
      return this.citiesRows.map((item) => {
        return {
          value: item.id,
          label: item.name[this.language],
        };
      });
    },
    branches() {
      return this.branchesRows.map((item) => {
        return {
          value: item.id,
          label: item.name[this.language],
        };
      });
    },
    languages() {
      return this.languagesRows.map((item) => {
        return {
          value: item.id,
          label: item.name[this.language],
        };
      });
    },
    isRTL() {
      return i18n.locale == 'ar';
    },
  },
  methods: {
    ...mapActions({
      doFetchCities: 'city/list/doFetch',
      doFetchBranches: 'branch/list/doFetch',
      doCreate: 'tourGuide/form/doCreate',
      doUpdate: 'tourGuide/form/doUpdate',
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.cities;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.citiesOptions = this.cities.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1,
        );
      });
    },

    filterFnBranches(val, update) {
      if (val === '') {
        update(() => {
          this.branchesOptions = this.branches;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.branchesOptions = this.branches.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1,
        );
      });
    },

    filterFnLanguages(val, update) {
      if (val === '') {
        update(() => {
          this.languagesOptions = this.languages;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.languagesOptions = this.languages.filter(
          (v) => v.value.toLowerCase().indexOf(needle) > -1,
        );
      });
    },

    onUpdate(data) {
      this.model.phoneNumber = data.formatInternational;
    },

    async uploadAvatar(path, request) {
      const ref = firebase.storage().ref();
      const fullPath = `${path}/${request.name}`;
      const task = ref.child(fullPath).put(request);

      await task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          const percent =
            (snapshot.bytesTransferred /
              snapshot.totalBytes) *
            100;
          console.log('precent', percent);
        },
        (error) => {
          // request.onError(error);
          console.log(error);
        },
        () => {
          task.snapshot.ref
            .getDownloadURL()
            .then(async (url) => {
              console.log(url);
              this.model.avatar.publicUrl = url;
              this.model.avatar.name = request.name;
              console.log(this.model.avatar);
              this.uploadLicense(
                `saryahCompany/user/${this.recordId}/license`,
                this.license,
              );
            })
            .catch((error) => {
              console.log('error', error);
            });
        },
      );
    },

    async uploadLicense(path, request) {
      const ref = firebase.storage().ref();
      const fullPath = `${path}/${request.file.name}`;
      console.log('full path', fullPath);
      const task = ref.child(fullPath).put(request.file);

      task.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
          const percent =
            (snapshot.bytesTransferred /
              snapshot.totalBytes) *
            100;
          console.log('precent', percent);
        }, (error) => {
          // request.onError(error);
          console.log(error);
        }, () => {
          task.snapshot.ref.getDownloadURL().then(async (url) => {
            console.log(url);
            this.model.license.publicUrl = url;
            this.model.license.name = request.file.name;
            console.log(this.model);
            // debugger
            // await firebase.database().ref()
            await this.doUpdate({
              id: this.recordId,
              values: {
                ...this.model,
              },
            });
            this.loadingImages = false;
            this.$emit('close', 'create');
          }).catch((error) => {
            console.log('error', error);
          });
        },
      );
    },
    selectAvatar(value) {
      this.avatar = value;
    },
    // async uploadLicense(){

    // }
    selectLicense(fileList) {
      console.log(fileList);
      this.license = fileList[0];
    },

    async doSubmit() {
      this.model.companyId = this.currentUser.companyId;
      this.model.isMale = this.isMale;
      this.model.languages = this.selectedLanguages;

      
      // await this.uploadAvatar(
      //   `saryahCompany/user/${this.recordId}/avatar`,
      //   this.avatar,
      // );

      // this.$emit('close','create')
      if (!this.avatar) {
        EventBus.$emit('imageIsRequired');
      } else if (!this.license) {
        EventBus.$emit('formImageIsRequired', 'license');
      } else {
        const record = await this.doCreate(this.model);
        this.recordId = record.id;
        console.log('recordId ==> ', this.recordId);
        this.loadingImages = true;
        debugger
        await this.uploadAvatar(
          `saryahCompany/user/${this.recordId}/avatar`,
          this.avatar,
        );
        debugger
        this.$emit('close');
      }
    },
  },
};
