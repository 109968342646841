//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TourGuideViewModal from '@/modules/tour-guide/components/tour-guide-view-modal.vue';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/vueI18n';
export default {
  name: 'app-tour-guide-list-table',
  data() {
    return {
      columns: [
        {
          name: 'fullName',
          field: 'fullName',
          label: 'Full Name',
          format: (val) => `${val}`,
          align: 'center',
          required: true,
        },
        // { name: 'lastName', align: 'center', label: 'Last Name', field: 'lastName', },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: 'Phone Number',
          align: 'center',
        },
        {
          name: 'email',
          field: 'email',
          label: 'Email',
          align: 'center',
        },
        {
          name: 'password',
          field: 'password',
          label: 'Password',
          align: 'center',
        },
        {
          name: 'branch',
          field: 'branch',
          label: 'Branch',
          align: 'center',
        },
        {
          name: 'registered',
          field: 'registered',
          label: 'registered',
          align: 'center',
        },
        {
          name: 'status',
          align: 'center',
          label: 'Status',
          field: 'status',
        },
        {
          name: 'action',
          align: 'center',
          label: 'Action',
          field: 'action',
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // rowsPerPage: this.getItemsPerPage
      },
      page: 1,
      // modal
      selectedRow: null,
      selectedId: null,
      dialogType: null,
      modalVisible: false,
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      rows: 'tourGuide/list/rows',
      loading: 'tourGuide/list/loading',
      disableNext: 'tourGuide/list/disableNext',
      disablePrevious: 'tourGuide/list/disablePrevious',
      isFirstPage: 'tourGuide/list/isFirstPage',
      isLastPage: 'tourGuide/list/isLastPage',
    }),
    isRTL() {
      return i18n.locale == 'ar';
    },
    getItemsPerPage() {
      // const { screen } = this.$q;
      // if (screen.lt.sm) {
      //     return 4;
      // }
      // if (screen.lt.md) {
      //     return 6;
      // }
      // if (screen.lt.lg) {
      //     return 9;
      // }
      // if (screen.lt.xl) {
      //     return 9;
      // }
      // return 9;
      return 10;
    },
  },
  methods: {
    onModalOpen(row, operation) {
      // debugger
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete';
          break;
        case 'edit':
          this.dialogType = 'edit';
          break;
        case 'view':
          this.dialogType = 'view';
          break;
        default:
          this.dialogType = undefined;
          break;
      }
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined;
      }, 500);
    },
    async doDestroyWithConfirm({ id }) {
      try {
        console.log(id);
        await this.doDisableAllSelected([id]);
        // console.log('id',id)
        // console.log('selected row',this.selectedRow)
        // let prescription = Object.assign({}, this.selectedRow);
        // console.log('prescccc',prescription)
        // prescription.postStatus = "destroyed"
        // prescription.rejectionReason = rejectionReason
        // prescription.createdAt = new Date(prescription.createdAt.seconds * 1000)
        // prescription.updatedAt = new Date(prescription.updatedAt.seconds * 1000)
        // delete prescription.id
        // console.log('DELETEDDDDD',prescription)
        // await this.doUpdate({id, values:prescription})
        this.onModalClose();
      } catch (error) {
        // no
      }
    },
    ...mapActions({
      doFetch: 'tourGuide/list/doFetch',
      setDisableNext: 'tourGuide/list/setDisableNext',
      setDisablePrevious:
        'tourGuide/list/setDisablePrevious',
      doDisableAllSelected:
        'tourGuide/list/doDisableAllSelected',
      finishLoading: 'tourGuide/list/finishLoading',
      doToggleStatus: 'iam/view/doToggleStatus'

    }),
    toggleStatus(row){
      this.doToggleStatus(row)
      row.disabled = !row.disabled
    },
    async goToPreviousPage() {
      console.log('prev');
      const orderBy = 'createdAt';
      const pagination = {
        sortBy: 'desc',
        limit: this.getItemsPerPage,
        action: 'prev',
        page: this.page,
      };
      await this.doFetch({
        companyId: this.currentUser.companyId,
        orderBy,
        pagination,
      });
      // await this.setDisablePrevious({companyId: this.currentUser.companyId, orderBy, pagination})
      // await this.setDisableNext({companyId: this.currentUser.companyId, orderBy, pagination})
      this.page -= 1;
      this.finishLoading();
    },
    async loadMore() {
      console.log('loadMore');
      const orderBy = 'createdAt';
      const pagination = {
        sortBy: 'desc',
        limit: this.getItemsPerPage,
        action: 'next',
        page: this.page,
      };
      await this.doFetch({
        companyId: this.currentUser.companyId,
        orderBy,
        pagination,
      });
      // await this.setDisableNext({companyId: this.currentUser.companyId, orderBy, pagination})
      // await this.setDisablePrevious({companyId: this.currentUser.companyId, orderBy, pagination})
      this.page += 1;
      this.finishLoading();
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    displayName(value) {
      return this.isRTL ? value.ar : value.en;
    },
  },
  async mounted() {
    const pagination = {
      sortBy: 'desc',
      limit: this.getItemsPerPage,
    };
    await this.doFetch({
      companyId: this.currentUser.companyId,
      orderBy: 'createdAt',
      pagination,
    });
    // await this.setDisablePrevious({companyId: this.currentUser.companyId, orderBy: 'createdAt', pagination})
    // await this.setDisableNext({companyId: this.currentUser.companyId, orderBy: 'createdAt', pagination})
    this.finishLoading();
  },
  components: {
    [TourGuideViewModal.name]: TourGuideViewModal,
  },
};
