//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TourGuideListTable from '@/modules/tour-guide/components/tour-guide-list-table.vue';
import TourGuideFormModal from '@/modules/tour-guide/components/tour-guide-form-modal.vue';

import { mapGetters } from 'vuex'
export default {
    data(){
      return {
        // search: ''
        modalVisible: false,
        dialogVisible: false,
        dialogType: null
      }
    },
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
            // return this.$t(key, args);
        },
        onOpenFormModal(operation){
          // debugger
          switch (operation) {
            case 'create':
              this.dialogType = 'create'
            break;
            case 'success':
              this.dialogType = 'success'
            break;
            default:
              this.dialogType = undefined
            break;
          }
          this.modalVisible = true
          this.dialogVisible = true
          

        },
        onModalFormClose(operation) {
          this.dialogVisible = false;
          setTimeout(() => {
              this.modalVisible = false;
              this.dialogType = undefined
              if(operation == 'create'){
                this.onOpenFormModal('success')
              }              
          }, 500);           
        },
        onModalClose() {
          this.dialogVisible = false;
          setTimeout(() => {
              this.modalVisible = false;
              this.dialogType = undefined
          }, 5000);
        }
    },
    components: {
      [TourGuideListTable.name]: TourGuideListTable,
      [TourGuideFormModal.name]: TourGuideFormModal
    },
    computed:{
      ...mapGetters({
        is_screen_xs: 'layout/is_screen_xs'
      })
    }
}
