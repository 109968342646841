//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getLanguageCode } from '@/i18n';
import { mapGetters, mapActions } from 'vuex'
import { i18n } from '@/vueI18n';
import moment from 'moment';

export default {
    name: 'app-tour-guide-view-modal',
    props:{
        visible:{
            type: Boolean,
            required: true
        },
        id:{
          type: String,
          required: true
        }
    },
    data(){
        return{
            language: getLanguageCode(),
            model: null,
            trips: [
                {
                id: 1,
                name: 'Makkah Trip',
                day: 'Sat',
                date: '30 March 2020',
                },
                {
                id: 2,
                name: 'Makkah Trip',
                day: 'Sat',
                date: '30 March 2020',
                },
                {
                id: 3,
                name: 'Makkah Trip',
                day: 'Sat',
                date: '30 March 2020',
                },
                {
                id: 4,
                name: 'Makkah Trip',
                day: 'Sat',
                date: '30 March 2020',
                },
            ],
            value:{
                fullName: 'Ahmed Omar',
                avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
                phoneNumber: '+966123456',
                branchName: 'Branch Name',
            },
        }
    },
    computed:{
        ...mapGetters({
            is_screen_xs: 'layout/is_screen_xs',
            record: 'tourGuide/view/record'
        }),
        isRTL(){
          return i18n.locale == 'ar'
        },
        dialogVisible: {
            get: function() {
                return this.visible;
            },

            set: function(value) {
                if (!value) {
                    this.$emit('close');
                }
            },
            },
    },
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
        // return this.$t(key, args);
        },
        ...mapActions({
          doFind: 'tourGuide/view/doFind'
        }),
        displayName(name){
          return this.isRTL ? name['ar'] : name['en']
        },
        displayDate(date){
          return moment(date).format('llll');
        }
    },
    async created(){
      console.log(this.id);
      await this.doFind(this.id)
      console.log(this.record);
    }
}
